import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const Navbar = props => {
    const current = window.location.pathname;
    return (
        <Grid container direction={"row"} className="navbar" justifyContent={"center"}>
            <Grid item xs={2} className="link-grid-item">
                <Link to="/"
                className={current === "/" ? "active" : ""}
                >Home</Link>
                </Grid>
                <Grid item xs={2} className="link-grid-item">
                <Link to="/movies"
                className={current === "/movies" ? "active" : ""}
                >Movies</Link>
                </Grid>
                <Grid item xs={2} className="link-grid-item">
                <Link to="/books" 
                className={current === "/books" ? "active" : ""}
                >Books</Link>
                </Grid>          
                <Grid item xs={2} className="link-grid-item">
                <Link to="/shop"
                className={current === "/shop" ? "active" : ""}
                >Shop</Link>
                </Grid> 
                <Grid item xs={2} className="link-grid-item">
                <Link to="/socials"
                className={current === "/socials" ? "active" : ""}
                >Socials</Link>
                </Grid>       
        </Grid>
    );
};

export default Navbar;