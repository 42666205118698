import { React } from "react";
import { Titlebar } from "../components";
import { Footer } from "../components";
import movies from "./../data/movies.json";

const Movies = props => {
    return (
        <div>
            <Titlebar />
            <div className="content">
            <h1>Movies</h1>
            {movies.map((movie) => { return <h2 key={movie.title}>{movie.title}</h2> })}
            </div>
            <Footer />
        </div>
    );
};

export default Movies;
