import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Books, Movies, Shop, Socials } from './views';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  { path: "/books", element: <Books /> },
  { path: "/movies", element: <Movies /> },
  { path: "/shop", element: <Shop /> },
  { path: "/socials", element: <Socials /> },
  { path: "*", element: <App /> },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
