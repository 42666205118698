import './App.css';
import { Titlebar } from './components';
import { Footer } from './components';

function App() {
  return (
    <div>
      <Titlebar />
      <div className="content">
      <h1>Home Page</h1>
      </div>
      <Footer />
    </div>
  );
}

export default App;
