import React from "react";
import { default as Navbar } from "./Navbar";

const Titlebar = (props) => {
    return (
        <div className="title-bar">
            <div className="title-text">
                <div className="headline">The Truth Is In The Movies</div>
                <div className="sitename">MovieSymbolism.com</div>
            </div>
            
            <Navbar />
        </div>
    );
};

export default Titlebar;