import React from "react";

const Footer = (props) => {
    return (
        <div className="footer">
            <hr />
            Copyright MovieSymbolism.com 2025
        </div>
    );
};

export default Footer;