import { React } from "react";
import { Titlebar } from "../components";
import { Footer } from "../components";

const Books = props => {
    return (
        <div>
            <Titlebar />
            <div className="content">
            <h1>Books</h1>
            </div>
            <Footer />
        </div>
    );
};

export default Books;