import { React } from "react";
import { Titlebar } from "../components";
import { Footer } from "../components";
import Links from "./../data/links.json";

const Socials = props => {
    return (
        <div>
            <Titlebar />
            <div className="content">
            <h1>Social Media</h1>
            {Links.map((link) => { return <h2 key={link.title}>{link.title}</h2> })}
            </div>
            <Footer />
        </div>
    );
};

export default Socials;
